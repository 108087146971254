// 请求接口获取数据
import axios from "axios";
import {ElMessage} from 'element-plus'

export const fetchInit = async (data, success, finallyFn) => {
    try {
        const response = await axios.post('/zd/api/init', data);
        if (response.status === 200) {
            if (success) success(response)
        }
    } catch (e) {
        if (e.response.data) {
            ElMessage.error(e.response.data)
            return
        }
        console.error('请求数据出错：', e);
        ElMessage.error(e)
    } finally {
        if (finallyFn) finallyFn();
    }

}


export const fetchQuery = async (data, success, finallyFn) => {
    try {
        const response = await axios.post('/zd/api/query', data);
        if (response.status === 200) {
            if (success) success(response)
        }

    } catch (e) {
        if (e.response.data) {
            ElMessage.error(e.response.data)
            return
        }
        console.error('请求数据出错：', e);
        ElMessage.error(e)
    } finally {
        if (finallyFn) finallyFn();
    }
}

