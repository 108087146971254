<template>
  <div class="page"
       v-loading="loading"
       element-loading-text="加载中..."
  >
    <el-switch
        v-model="dark"
        :active-action-icon="Moon"
        :inactive-action-icon="Sunny"
        class="dark-switch"
    />
    <el-form inline style="text-align: left;padding-left: 10px">
      <el-form-item label="日期">
        <el-select
            v-model="value"
            placeholder="选择日期"
            style="width: 240px"
            @change="onSearch"
        >
          <el-option v-for="item in options" :key="item" :label="item" :value="item"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :icon="RefreshRight" @click="onSearch">刷新</el-button>
        <el-button type="success" :loading="exporting" :icon="Download" @click="onExport">导出Excel</el-button>
      </el-form-item>
    </el-form>

    <div class="my-card">
      <div class="title">
        <el-icon>
          <WalletFilled/>
        </el-icon>
        <span>入款 ({{ yrk_records.length }}笔)</span></div>
      <el-table stripe border :data="yrk_records" :show-header="true" class="my-table">
        <el-table-column prop="时间" label="时间">
          <template #default="scope">
            <div>
              <span style="font-weight: bold">{{ parseTime(scope.row.时间, "{m}-{d}") }}</span><br>
              <span>{{ parseTime(scope.row.时间, "{h}:{i}") }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="金额" label="金额">
          <template #default="scope">
            <div>
              <span style="font-weight: bold">{{ scope.row.金额 }}</span><br>
              <span>{{ scope.row.金额 }} / {{ scope.row.除数 }}</span>
            </div>
          </template>

        </el-table-column>
        <el-table-column prop="换算" label="换算"/>
        <el-table-column prop="操作人" label="操作人"/>

        <template #empty>
          <el-empty description="暂无数据" :image-size="60"/>
        </template>
      </el-table>
    </div>

    <div class="my-card">
      <div class="title">
        <el-icon>
          <Flag/>
        </el-icon>
        <span>下发 ({{ yxf_records.length }}笔)</span></div>
      <el-table stripe border :data="yxf_records" :show-header="true" class="my-table">
        <el-table-column prop="时间" label="时间">
          <template #default="scope">
            <div>
              <span style="font-weight: bold">{{ parseTime(scope.row.时间, "{m}-{d}") }}</span><br>
              <span>{{ parseTime(scope.row.时间, "{h}:{i}") }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="金额" label="金额">
          <template #default="scope">
            <div>
              <span style="font-weight: bold">{{ scope.row.金额 }}</span><br>
              <span>*{{ scope.row.除数 }}</span>
            </div>
          </template>

        </el-table-column>
        <el-table-column prop="换算" label="换算"/>
        <el-table-column prop="操作人" label="操作人"/>

        <template #empty>
          <el-empty description="暂无数据" :image-size="60"/>
        </template>
      </el-table>
    </div>

    <el-row :span="24">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="overflow: auto">
        <div class="my-card">
          <div class="title">
            <el-icon>
              <Promotion/>
            </el-icon>
            <span>群组信息</span></div>
          <el-descriptions
              :column="1"
              border
          >
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">群ID</div>
              </template>
              <el-tag size="small">{{ main_config.群ID }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">群名称</div>
              </template>
              <el-tag size="small">{{ main_config.群名 }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">邀请人</div>
              </template>
              <el-tag size="small">{{ main_config.昵称 }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">进群时间</div>
              </template>
              <el-tag size="small">{{ main_config.进群时间 }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item class-name="my-class"
                                  label="操作人"
                                  label-class-name="my-label">
              {{
                main_config.操作人
              }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div class="my-card">
          <div class="title">
            <el-icon>
              <Histogram/>
            </el-icon>
            <span>总计</span></div>
          <el-descriptions
              :column="1"
              border
          >
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">当前汇率</div>
              </template>
              <el-tag size="small">{{ main_config.费率 ? main_config.费率 : 0 }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">总入款</div>
              </template>
              <el-tag size="small">{{ sub_config.总入款 }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">已下发</div>
              </template>
              <el-tag size="small" type="warning">{{ sub_config.总下发 }} USDT</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">应下发</div>
              </template>
              <el-tag size="small" type="success">{{ sub_config.应下发 }} USDT</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">待下发</div>
              </template>
              <el-tag size="small" type="danger">{{ sub_config.待下发 }} USDT</el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {ref} from "vue";
import {fetchInit, fetchQuery} from "@/api/api";
import {parseTime} from "@/utils/ruoyi";
import {RefreshRight, Download, Sunny, Moon} from "@element-plus/icons-vue";
import {useDark, useToggle} from '@vueuse/core'


const isDark = useDark()
const toggleDark = useToggle(isDark)
export default {
  components: {},
  data() {
    return {
      loading: false,
      exporting: false,
      Sunny: Sunny,
      Moon: Moon,
      RefreshRight: RefreshRight,
      Download: Download,
      startTime: '',
      endTime: '',
      yrk_records: [],
      yxf_records: [],
      sub_config: {},
      main_config: {},
      value: ref(''),
      dark: isDark,
      options: [],
      qid: null
    };
  },
  mounted() {
    // 获取URL的qid、date参数
    const queryString = window.location.search;
    // 使用 URLSearchParams 解析查询参数
    const urlParams = new URLSearchParams(queryString);
    this.qid = urlParams.get('id');
    this.value = urlParams.get('date');
    this.loading = true
    fetchInit({qid: this.qid}, (res) => {
      this.options = res.data.dates;
      if (this.options.length > 0 && !this.value) {
        this.value = this.options[0];
        this.onSearch();
      }
    }, () => {
      this.loading = false
    })
  },
  methods: {
    parseTime,
    onSearch() {
      this.loading = true
      fetchInit({qid: this.qid}, (res) => {
        this.options = res.data.dates;
      });
      fetchQuery({
        date: this.value,
        qid: this.qid
      }, (res) => {
        this.yxf_records = res.data.yxf_records;
        this.yrk_records = res.data.yrk_records;
        this.sub_config = res.data.sub_config;
        this.main_config = res.data.main_config;
      }, () => {
        this.loading = false
      })
    },
    onRefresh() {
      console.log('刷新数据');
      // 在这里处理刷新逻辑
    },
    onExport() {
      const url = '/zd/api/download-excel?date=' + this.value + '&qid=' + this.qid
      const a = document.createElement('a')
      a.setAttribute('target', '_blank')
      a.setAttribute('href', url)
      a.click()
    },
  },
  watch: {
    dark() {
      toggleDark(this.dark)
    }
  },
};
</script>

<style>
.my-card {
  padding: 10px;
  margin: 10px 5px;
  border: var(--el-border-color) solid 1px;
  border-radius: 5px;
}


.title {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  margin: 0 0 10px 0;
}

.title span {
  padding-left: 5px;
}

/deep/ .el-card__body {
  padding: 5px 10px;
}

.page {
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  min-height: 80vh;
}

.cell-item {
  width: 60px;
}

/deep/ .el-empty {
  padding: 10px;
}

.dark-switch {
  position: fixed;
  right: 10px;
  top: 10px;
}


/deep/ .el-descriptions-item__content {
  max-width: 200px;
}

.my-label {
  width: 200px;
  color: #999;
  font-weight: normal;
  background: #fff;
}

.my-class {
  max-width: 200px;
  word-break: break-all;
  word-wrap: break-word;
}


</style>